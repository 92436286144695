:root {
  --mygreen: #35b0ab;
  --myred: #ff6666;
  --mywhite: #f7f4f3;
  --myblue: #22577a;
}

body {
  margin: 0;
  padding: 0;
  background-color: var(--mywhite);
}

.mygreen {
  color: var(--mygreen);
}

.mywhite-bg {
  background-color: var(--mywhite);
}

.myred-bg {
  background-color: var(--myred);
}

#logo-container {
  max-height: 100%;
}

.parallax-container {
  min-height: 380px;
  line-height: 0;
  height: auto;
  color: rgba(255, 255, 255, 0.9);
}
.parallax-container .section {
  width: 100%;
}

nav ul > li {
  background-color: var(--myred);
  border-right: 1px solid var(--mywhite);
}

.mobile-menu {
  color: var(--myred);
}

#nav-mobile li {
  background-color: var(--myred);
}

.shadowed {
  background-color: rgba(0, 0, 0, 0.16);
}

.parallax-container .parallax img {
  width: 100%;
}

h1 {
  color: var(--mygreen);
  font-weight: strong;
}

a.anchor {
  display: block;
  position: relative;
  top: -64px;
  visibility: hidden;
}

h2 {
  padding-top: 20px;
}

.page-footer {
  background-color: var(--myred);
  padding: 20px 0;
}

.page-footer .container {
  display: flex;
  align-items: center;
}

.footer-info {
  display: flex;
}

.page-footer p {
  margin: 0 15px 0 0;
}

.footer-links {
  display: flex;
  margin: 0;
  text-align: center;
}

.page-footer li {
  display: inline-flex;
  padding-right: 8px;
}

.page-footer a {
  color: var(--mywhite);
  line-height: 0.75rem;
}
